<template>
	<div>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;" justify="center">
							<v-col cols="6" md="2" lg="2">
								<v-btn @click="clickPallet()">Armar Pallet</v-btn>
							</v-col>
							<v-col cols="4" md="3" lg="3">
								<s-date
									label="Fecha Inicio"
									v-model="cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="4" md="3" lg="3">
								<s-date label="Fecha Fin" v-model="cDateFin"></s-date>
							</v-col>
							<v-col cols="4" md="3" lg="3">
								<s-select-definition
								label="Planta procesadora"
								v-model="TypePlantProcessing"
								:def="1230">

								</s-select-definition>
							</v-col>
							<v-col cols="4" md="3" lg="3">
								<s-select-definition
									:dgr="dgr"
									label="Linea Empaque"
									:def="1315"
									v-model="TypeLineProcess"
									:clearable="$fun.isAdmin()"
								>
								</s-select-definition>
							</v-col>
						</v-row>
					</v-card>
				</div>
				<div class="col-md-12 col-sm-12" style="margin-top: -20px;">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col cols="6" lg="3" md="4" v-for="col in itemsPallet">
								<v-card style="display: grid; background: #eaeaea;" >
									<!--:disabled="col.inCamera == 1 ? true: false"-->
									<v-btn color="warning" 
									v-if="col.inCamera == 1" style="z-index: 2;transform: rotateZ(316deg);margin-top: 20px;margin-left: -30px;position: absolute;width: 133px;border-radius: 20px;"> 
									{{col.CameraName}}
									</v-btn>

									<v-checkbox
										style="display: contents;"
										v-model="col.TypeBalance"
									>
									</v-checkbox>
									<!-- <v-btn
										x-small
										elevation="0"
										fab
										dark
										color="error"
										style="margin-left: 0px; margin-top: -20px; "
									>
										<v-icon style="font-size: 16px !important">fas fa-times</v-icon>
									</v-btn> -->
									<v-card-title
										style="justify-content: center; margin-top: -50px;"
									>
										<span class="text-h6 font-weight-light"
											>Pallet Nro:
											<v-chip
												style="margin: 0px"
												x-small
												:color="'success'"
												>{{ col.PltID }}
											</v-chip>
										</span>
									</v-card-title>
									<v-img
										lazy-src="../../../assets/pallets44.png"
										max-height="350"
										max-width="350"
										src="../../../assets/pallets44.png"
									>
										<v-btn
											style="border-radius: 100%; height: 74%; width: 50%; margin: auto; display: flex; justify-content: space-evenly; "
											color="success"
										>
											<span
												class="white--text text-h3"
												style="margin-top: -30px;"
											>
												{{ col.UwoMaterialListNumberBoxes }}
												<!-- <v-img
                          lazy-src="../../../assets/jaba.png"
                          max-height="60"
                          max-width="60"
                          src="../../../assets/jaba.png"
                          style="margin-top: -8px;"
                        >
                        </v-img> -->
											</span>
											<span
												style="position: absolute; font-weight: bold; font-size: 18px; color: ;margin-top: 30px;"
											>
												{{ col.VrtName }}
											</span>
										</v-btn>
									</v-img>

									<v-col cols="12" align="center">
										<h4>{{col.UwoCustomerName}} - {{col.OrdID}}</h4>
										<h4>{{col.TypeCaliberName}}</h4>
										<h4>{{col.UwoMaterialListName}}</h4>
										<h2>{{col.TypeDestinyPacking}}</h2>
										<h2>{{col.TypeLineName}}</h2>
									</v-col>

									
								</v-card>



								<v-card>
									<v-row
										style="margin-top: 0.1em; margin-left: inherit;"
									>
										<v-col cols="6" lg="6" md="6">
											<s-text
												label="Cultivo:"
												readonly
												v-model="col.TypeCultiveName"
											></s-text>
										</v-col>
										<v-col cols="6" lg="6" md="6">
											<s-text
												label="Tipo Cultivo:"
												readonly
												v-model="col.TypeCropName"
											></s-text>
										</v-col>
									</v-row>
								</v-card>

								<!--  {{col}} -->
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			transition="dialog-bottom-transition"
			width="60%"
			hide-overlay
		>
			<packing @click="click($event)"></packing>
		</v-dialog>

		<v-dialog
			v-if="openRePacking"
			v-model="openRePacking"
			persistent
			fullscreen
			transition="dialog-bottom-transition"
		>
			<packing-balance
				:itemsRe="itemsRePacking"
				:ordAreProduction="ordAreProduction"
				@close="closeModal()"
				:TypeDestiny="TypeDestiny"
			></packing-balance>
		</v-dialog>
	</div>
</template>
<script>
	//Service
	import _sPackingService from "../../../services/FreshProduction/PackingService";
	import Packing from "./Packing.vue";
	import PackingBalance from "./PackingBalance.vue";

	export default {
		components: { Packing, PackingBalance },
		data: () => ({
			itemsPallet: [],
			PalletNro: 0,
			ordAreProduction: "",
			cDateInitial: null,
			cDateFin: null,
			TypePlantProcessing: 0,
			openDialog: true,
			openRePacking: false,
			itemsRePacking: [],
			TypeDestiny: 0,
			TypeLineProcess: null,
			dgr: 70,
		}),

		watch: {
			cDateInitial() {
				this.initialize();
			},
			cDateFin() {
				this.initialize();
			},
			TypePlantProcessing(){
				this.initialize();
			},
			TypeLineProcess(){
				this.initialize();
			}
		},
		methods: {
			initialize() {
				_sPackingService
					.GetRePackingSearch(
						{
							OrdAreProduction: this.ordAreProduction,
							cDateInitial: this.cDateInitial,
							cDateFin: this.cDateFin,
							TypePlantProcessing: this.TypePlantProcessing,
							TypeLine: this.TypeLineProcess
						},
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsPallet = r.data;
						console.log("saldos",this.itemsPallet);
					});
			},

			closeModal() {
				this.openRePacking = false;
				this.initialize();
			},

			click(val) {
				console.log("click", val);
				this.TypeDestiny = val.DedValue;
				this.ordAreProduction = val.DedAbbreviation;
				this.initialize();
				this.openDialog = false;
			},

			clickPallet() {
				this.itemsRePacking = this.itemsPallet.filter(
					x => x.TypeBalance == true,
				);

				if (this.itemsRePacking.length > 0) {
					this.openRePacking = true;
				}
			},
		},
	};
</script>
